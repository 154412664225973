import ReactDom from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./pages/Home/Home";
import NoPage from "./pages/NoPage";
import Phrases from "./pages/Phrases/Phrases";
import PleaseWait from "./pages/PleaseWait/PleaseWait";
import './App.css';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="pleasewait" element={<PleaseWait />}/>
            <Route path="phrases" element={<Phrases />}/>
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
