import {
    createTheme,
    ThemeProvider,
    CssBaseline,
    Container,
    Typography,
    Button,
  } from "@mui/material";
  import { createClient } from "@supabase/supabase-js";
  import { useEffect, useState } from "react";
  import styles from './Home.module.css'

const theme = createTheme({
    palette: {
      mode: "light",
      background: {
        default: "#FF9770",
      },
    },
    typography: {
      fontFamily: ["sans-serif", "Raleway"],
    },
  });

export default function Home() {

  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
  const supabaseClient = createClient(supabaseUrl, supabaseKey);

  const [content, setContent] = useState(null);

  useEffect(() => {

    document.title = 'AC - Home';

    async function fetchData() {
      const { data: things_i_made, error } = await supabaseClient.from('things_i_made').select('*').order('timestamp', { ascending: true });
      return things_i_made;
    } 

    if (content === null) {
      fetchData().then((data) => {
        //console.log({data})
        setContent(data);
      })
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={styles.background}>
        <Container
          maxWidth={"true"}
          sx={{
            padding: "0 !important",
            height: "100vh",
            position: "relative",
            overflow: "clip",
          }}
        >
          <div className={[styles.cloud, styles.cloudHigh, styles.cloudSlow].join(" ")}>
            <div style={{ position: "relative" }}>
              <div className={[styles.cloud, styles.cloudSmall, styles.left].join(" ")}></div>
            </div>
          </div>
          <div className={[styles.cloud, styles.cloudLow, styles.cloudMed].join(" ")}>
            <div style={{ position: "relative" }}>
              <div className={[styles.cloud, styles.cloudSmall, styles.right].join(" ")}></div>
            </div>
          </div>
          <div className={[styles.cloud, styles.cloudMid, styles.cloudFast].join(" ")}>
            <div style={{ position: "relative" }}>
              <div className={[styles.cloud, styles.cloudSmall, styles.mid].join(" ")}></div>
            </div>
          </div>
          <div className={styles.logo}>
            <div>
              <Typography variant="h1">AC</Typography>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.content}>
        {content?.map((c, i) => {
          return (
            <div className={styles.contentItem} key={i}>
              <div>
                <div className={styles.contentTitle}>
                  <Typography variant="h2" align={"center"} fontWeight={"400"}>
                    {c.title}
                  </Typography>
                </div>
                <div className={styles.contentBody}>
                  <Typography variant="h6">
                    {c.body}
                  </Typography>
                  <div className={styles.contentLink}>
                  <Button href={c.link}
                          target="_blank" 
                          variant={'contained'}
                          sx={{width: '200px'}}>
                    <Typography>
                      jump on over
                    </Typography>
                  </Button>
                </div>
                </div>
              </div>
              <div></div>  
            </div>
          );
        })}
        <div className={styles.spacer}></div>
      </div>
      <div className={styles.watermark}>
        <p>a-crawley 2022</p>
      </div>
      <div className={styles.links}>
        <a href="https://github.com/A-Crawley" target="_blank" rel="noreferrer">
          <img src="./GitHub-Mark-120px-plus.png" alt="github"/>
        </a>
      </div>
    </ThemeProvider>
  );
}